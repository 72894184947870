// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-detail-page-js": () => import("./../../../src/templates/detail-page.js" /* webpackChunkName: "component---src-templates-detail-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-list-page-js": () => import("./../../../src/templates/list-page.js" /* webpackChunkName: "component---src-templates-list-page-js" */),
  "component---src-templates-resume-page-js": () => import("./../../../src/templates/resume-page.js" /* webpackChunkName: "component---src-templates-resume-page-js" */)
}

